import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PersonalCabinet from './pages/PersonalCabinet';
import { getCookie } from './utils/cookies';
import { UserProvider } from './context/UserContext';  // Импортируем новый контекст

const PrivateRoute = ({ children }) => {
  const token = getCookie('authToken');
  return token ? children : <Navigate to="/" />;
};

function App() {
  return (
    <UserProvider>  {/* Теперь передаём ВСЮ информацию о пользователе */}
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/personalCabinet"
            element={
              <PrivateRoute>
                <PersonalCabinet />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
