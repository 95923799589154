// Функция для установки куки
export const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Устанавливаем срок жизни куки
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/; Secure; SameSite=Strict`;
  };
  
  // Функция для чтения куки
  export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  // Удаление куки
    export const deleteCookie = (name) => {
    document.cookie = `${name}=; Max-Age=-99999999; path=/; Secure; SameSite=Strict`;
  };
  