import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCookie } from '../utils/cookies'; // Функция для чтения куки

const UserContext = createContext();  // Теперь это UserContext

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Храним весь объект пользователя
  const [isPopupOpen, setPopupOpen] = useState(false); // Добавляем управление попапом

  const fetchUserInfo = async () => {
    const token = getCookie('authToken');;
    const response = await fetch(`https://api.anymessage.shop/user/information?token=${token}`);

    if (response.ok) {
      const data = await response.json();
      console.log('Данные пользователя:', data);
      setUser(data.data); // Сохраняем ВСЕ данные пользователя, а не только баланс
    } else {
      console.error('Ошибка получения данных');
    }
  };

  useEffect(() => {
    fetchUserInfo(); // Загружаем данные при запуске
  }, []);

  return (
    <UserContext.Provider value={{ user, fetchUserInfo, isPopupOpen, setPopupOpen }}>
      {children}
    </UserContext.Provider>
  );
};

// Хук для использования данных пользователя в компонентах
export const useUser = () => useContext(UserContext);
