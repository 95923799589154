import React, { useState, useEffect } from 'react';
import { authorize } from '../../api/auth'; // Импорт функции авторизации
import { register } from '../../api/register'; // Импорт функции регистрации
import { setCookie } from '../../utils/cookies'; // Импорт функции для установки куки
import gmailIcon from '../../assets/gmail-icon.svg';
import telegramIcon from '../../assets/telegram-icon.svg';
import { useNavigate } from 'react-router-dom';
import './AuthPopup.css';
import { useTranslation } from 'react-i18next';

const AuthPopup = ({ isOpen, onClose, isLogin: initialIsLogin, onForgotPassword }) => {
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(initialIsLogin);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Управление видимостью пароля
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(null); // ✅ Статус совпадения паролей
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [locale] = useState('ru'); // Локализация по умолчанию
  const [serverError, setServerError] = useState(''); // ✅ Ошибка с сервера
  const navigate = useNavigate();  // Для перенаправления

  useEffect(() => {
    setIsLogin(initialIsLogin); // Устанавливаем состояние при открытии попапа
  }, [initialIsLogin]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };


 // ✅ Проверка паролей в реальном времени
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    updatePasswordMatch(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    updatePasswordMatch(password, newConfirmPassword);
  };

  const updatePasswordMatch = (pass, confirmPass) => {
    if (!confirmPass) {
      setPasswordMatch(null);
    } else {
      setPasswordMatch(pass === confirmPass);
    }
};




const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setLoading(true);

  try {
    let response;

    if (isLogin) {
        response = await authorize(email, password);

        console.log('📩 Ответ сервера (авторизация):', response);

        if (response?.status === 'success' && response?.data?.token) {
            setCookie('authToken', response.data.token, 7);
            console.log('✅ Токен сохранён:', response.data.token);
            navigate('/personalCabinet');
            return;
        } 

        if (response?.status === 'error') {
            if (response?.value === 'wrong user or password') {
                setError(t('auth.error_wrong_credentials')); // ❌ Перевод "Неверный email или пароль"
                return;
            }
            setError(t('auth.error_generic', { error: response.value }));
            return;
        }

        setError(t('auth.error_unknown'));
        return;
    } 
      
      // 📝 Регистрация
      response = await register(email, password, 'en');

      console.log('📩 Ответ сервера (регистрация):', response);

      if (response?.status === 'success') {
          alert(t('auth.success_registration'));
          setIsLogin(true);
          return;
      } 
      
      // ✅ Обрабатываем ошибки сервера без `throw`
      if (response?.status === 'error') {
          if (response?.value === 'user already exists') {
              setError(t('auth.error_user_exists'));
              return;
          }
          if (response?.value === 'invalid email') {
              setError(t('auth.error_invalid_email'));
              return;
          }
          setError(t('auth.error_generic', { error: response.value }));
          return;
      }

      setError(t('auth.error_unknown'));
  } catch (err) {
      console.error('❌ Ошибка запроса:', err);
      setError(t('auth.error_server'));
  } finally {
      setLoading(false);
  }
};



  
  
  
  

  return (
    <div className="popup-overlay" onMouseDown={handleOverlayClick}>
      <div className="popup-content" onMouseDown={(e) => e.stopPropagation()}>
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        {isLogin ? (
          <>
            <h2>{t('auth.login')}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{t('auth.email')}</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('auth.enter_email')}
                  required
                />
              </div>
              <div className="form-group">
                <label>{t('auth.password')}</label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t('auth.enter_password')}
                    required
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? '🙈' : '👁️'}
                  </button>
                </div>
              </div>
              {error && <p className="auth-error-message">{error}</p>}


              <button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? t('auth.loading') : t('auth.login_submit')}
              </button>
            </form>
            <p>
              {t('auth.forgot_password')}{' '}
              <button className="link-btn" onClick={onForgotPassword}>
                {t('auth.recover_password')}
              </button>
            </p>
            <p>
              {t('auth.no_account')}{' '}
              <button className="link-btn" onClick={() => setIsLogin(false)}>
                {t('auth.register')}
              </button>
            </p>
          </>
        ) : (
          <>
            <h2>{t('auth.register')}</h2>

            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{t('auth.email')}</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('auth.enter_email')}
                  required
                />

              </div>
              <div className="form-group">
                <label>{t('auth.password')}</label>
                <div className="password-input-wrapper">
                  <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder={t('auth.enter_password')}
                    required
                  />
                  {/* ✅ Если пароль введён, показываем галочку */}
                  {password && <span className="password-icon success">✅</span>}   
                  </div>
              </div>

              {/* ✅ Новое поле подтверждения пароля */}
              <div className="form-group">
                <label>{t('auth.confirm_password')}</label>
                <div className="password-input-wrapper">
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    placeholder={t('auth.reenter_password')}
                    required
                  />
                {/* ✅ Показываем зеленую галочку или красный крестик в зависимости от совпадения паролей */}
                {confirmPassword && (
                <span className={`password-icon ${passwordMatch === null ? 'neutral' : passwordMatch ? 'success' : 'error'}`}>
                  {passwordMatch === null ? '⚪' : passwordMatch ? '✅' : '❌'}
               </span>
                )}
                </div>
                {/* ✅ Сообщение о совпадении паролей с новым классом */}
                {passwordMatch !== null && (
                      <p className={passwordMatch ? 'password-success-message' : 'password-error-message'}>
                        {passwordMatch ? '✅ Пароли совпадают' : '❌ Пароли не совпадают'}
                      </p>
                )}

              </div>


              {/* ✅ Отображение ошибки с сервера */}
              {error && <p className="auth-error-message">{error}</p>}



             {/* ✅ Кнопка заблокирована, если пароли не совпадают */}
            <button type="submit" className="btn btn-primary" disabled={loading || !passwordMatch}>
              {loading ? t('auth.loading') : t('auth.register_submit')}
            </button>
          </form>



            <div className="social-icons">
              <div className="social-icon">
                <img src={gmailIcon} alt="Gmail" />
                <p>{t('auth.register_gmail')}</p>
              </div>
              <div className="social-icon">
                <img src={telegramIcon} alt="Telegram" />
                <p>{t('auth.register_telegram')}</p>
              </div>
            </div>
            <p>
              {t('auth.already_have_account')}{' '}
              <button className="link-btn" onClick={() => setIsLogin(true)}>
                {t('auth.login')}
              </button>
            </p>
          </>
        )}
      </div>
    </div>

  );
};

export default AuthPopup;
