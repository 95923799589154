import { useTranslation } from 'react-i18next';
import './SupportButton.css';
// import telegramIcon from '../assets/telegram.svg'; // Добавь иконку Telegram в assets

const SupportButton = () => {
  const { t } = useTranslation('support');

  return (
    <a
      href="https://t.me/anymessage_support"
      target="_blank"
      rel="noopener noreferrer"
      className="support-button"
      title={t('support.telegram_button')}
    >
      <span>🆘</span>
      {/* <img src="support.svg" alt="Telegram" className="support-icon" /> */}
      <span className="tooltip">{t('support.telegram_button')}</span>
    </a>
  );
};

export default SupportButton;
