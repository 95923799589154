const API_BASE = 'https://api.anymessage.shop';

export const register = async (email, password, locale) => {
  console.log('📤 Отправляем данные:', { email, password, localization: locale });

  try {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('localization', locale);

    const response = await fetch(`${API_BASE}/user/registration`, {
      method: 'POST',
      body: formData,
    });

    console.log('📩 HTTP статус:', response.status);

    const data = await response.json(); // ✅ Теперь response.json() вызываем только ОДИН раз!
    console.log('📩 Ответ сервера:', data);

    // ✅ Возвращаем полный ответ сервера вместо выброса ошибки
    return data;
  } catch (error) {
    console.error('❌ Ошибка регистрации:', error.message);

    // ✅ Теперь возвращаем объект ошибки вместо `throw new Error()`
    return { status: 'error', value: error.message || 'Ошибка регистрации' };
  }
};
