export const authorize = async (email, password) => {
  try {
      console.log('📤 Отправка данных для авторизации:', { email, password });

      const response = await fetch('https://api.anymessage.shop/user/authorize', {
          method: 'POST',
          headers: { 'Accept': 'application/json' }, // ✅ Добавляем заголовок Accept
          body: new URLSearchParams({ email, password }), // ✅ Отправляем данные в формате `application/x-www-form-urlencoded`
      });

      console.log('📩 HTTP статус авторизации:', response.status);

      // ✅ Проверяем успешность HTTP-ответа
      if (!response.ok) {
          const errorData = await response.json();
          console.error('❌ Ошибка сервера:', errorData);
          return { status: 'error', value: errorData.value || 'Ошибка авторизации' };
      }

      const data = await response.json(); // ✅ Декодируем JSON только ОДИН раз
      console.log('📩 Ответ сервера (авторизация):', data);

      return data; // ✅ Возвращаем JSON, чтобы `handleSubmit` мог обработать ответ
  } catch (error) {
      console.error('❌ Ошибка запроса:', error.message);
      return { status: 'error', value: 'Ошибка соединения с сервером' };
  }
};
