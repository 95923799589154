import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageDropdown.css'; // Стили для выпадающего меню

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="language-dropdown">
      <button className="dropdown-btn">
        🌐
      </button>
      <div className="dropdown-content">
        <button onClick={() => changeLanguage('en')}>🇬🇧 English</button>
        <button onClick={() => changeLanguage('ru')}>🇷🇺 Русский</button>
        <button onClick={() => changeLanguage('uk')}>🇺🇦 Українська</button>
        <button onClick={() => changeLanguage('zh')}>🇨🇳 中文</button>
      </div>
    </div>
  );
};

export default LanguageDropdown;
