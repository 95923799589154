import React, { useState, useEffect } from 'react';
import './PersonalCabinet.css';
import HeaderCabinet from '../components/Header/Header';
import EmailPurchase from '../components/EmailPurchase/EmailPurchase';
import SupportButton from '../components/SupportButton/SupportButton';
import { getCookie } from '../utils/cookies'; // Функция для чтения куки
import { useTranslation } from 'react-i18next';




const PersonalCabinet = () => {
  const { t } = useTranslation('email_purchases');
  const [balance, setBalance] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [customAmount, setCustomAmount] = useState('');
  const [siteName, setSiteName] = useState('');
  const [selectedEmailTypes, setSelectedEmailTypes] = useState(['any']);
  const [emailQuantity, setEmailQuantity] = useState(1);
  const [filterType, setFilterType] = useState('all');
  const [copyStatus, setCopyStatus] = useState({});
  const [emails, setEmails] = useState([]); // Список заказанных почт
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [emailTypeName, setEmailTypeName] = useState('Любая (от 1$)');
  const [isLoading, setIsLoading] = useState(true);
  const [updatingStatusId, setUpdatingStatusId] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false); // Индикатор загрузки

  
  

  const emailTypes = [
    { type: 'gmail', label: 'Gmail ($5)', price: 5, category: 'popular', icon: '../default-logo.svg' },
    { type: 'yahoo', label: 'Yahoo ($3)', price: 3, category: 'popular', icon: '../default-logo.svg'},
    { type: 'yandex', label: 'Yandex ($2)', price: 2, category: 'local', icon: '../default-logo.svg' },
    { type: 'mailru', label: 'Mail.ru ($1)', price: 1, category: 'local', icon: '../default-logo.svg' },
    { type: 'outlook', label: 'Outlook ($4)', price: 4, category: 'popular', icon: '../default-logo.svg' },
    { type: 'protonmail', label: 'ProtonMail ($6)', price: 6, category: 'secure', icon: '../default-logo.svg' },
    { type: 'zoho', label: 'Zoho ($2)', price: 2, category: 'business', icon: '../default-logo.svg'},
    { type: 'icloud', label: 'iCloud ($3)', price: 3, category: 'apple', icon: '../default-logo.svg' },
    { type: 'gmx', label: 'GMX ($1)', price: 1, category: 'local', icon: '../default-logo.svg' },
    { type: 'aol', label: 'AOL ($3)', price: 3, category: 'vintage', icon: '../default-logo.svg' },
    { type: 'hushmail', label: 'Hushmail ($7)', price: 7, category: 'secure', icon: '../default-logo.svg' },
    { type: 'fastmail', label: 'Fastmail ($4)', price: 4, category: 'premium', icon: '../default-logo.svg' },
  ];
  

  // 1️⃣ Загружаем информацию о пользователе (баланс)
useEffect(() => {
  const fetchUserInfo = async () => {
      const token = getCookie('authToken');
      if (!token) {
          alert('Вы не авторизованы. Пожалуйста, войдите в систему.');
          window.location.href = '/login';
          return;
      }

      try {
          const response = await fetch('https://api.anymessage.shop/user/information', {
              method: 'GET',
              headers: { Authorization: `Bearer ${token}` },
          });

          if (!response.ok) throw new Error('Ошибка получения данных');

          const data = await response.json();
          setBalance(data.data.balance);
          console.log('Данные пользователя:', data);
      } catch (err) {
          console.error('Ошибка загрузки данных пользователя:', err.message);
      }
  };

  fetchUserInfo();
}, []); // Выполняется один раз при загрузке



const fetchEmailHistory = async (page = 1) => {
  const token = getCookie('authToken');
  if (!token) return;

  const url = new URL('https://api.anymessage.shop/email/history');
  url.searchParams.append('token', token);
  url.searchParams.append('page', page.toString());

  console.log(`📩 Запрашиваем историю писем (страница ${page}):`, url.toString());

  try {
    setLoading(true);  // Начинаем загрузку

    const response = await fetch(url.toString(), { method: 'GET' });

    if (!response.ok) {
      throw new Error('Ошибка при загрузке истории писем');
    }

    const data = await response.json();
    console.log('📩 История писем получена:', data);

    if (data.status === 'success' && Array.isArray(data.data)) {
      setEmails(data.data
        .filter(email => email.status !== "cancel") // ✅ Исключаем отменённые письма
        .map(email => ({
          id: email.id,
          site: email.from, 
          email: email.email,
          status: email.status,
          message: email.message || null, // ✅ Сохраняем HTML письма
          received_at: email.received_at
        }))
        .sort((a, b) => b.received_at - a.received_at) // ✅ Сортируем (новые сверху)
      );

      setHasNextPage(data.next_page); // ✅ Запоминаем, есть ли следующая страница
    }
  } catch (error) {
    console.error('❌ Ошибка при загрузке истории писем:', error);
  }

  setLoading(false);  // Завершаем загрузку
};



useEffect(() => {
  setCopyStatus(prev => {
      const updatedCopyStatus = {};
      emails.forEach(email => {
          updatedCopyStatus[email.id] = prev[email.id] || { id: false, email: false };
      });
      return updatedCopyStatus;
  });
}, [emails]); // Запускается при загрузке email


// 2️⃣ Загружаем историю почт
useEffect(() => {
  fetchEmailHistory();
}, []); // Выполняется один раз при загрузке

  
    // 2. Выход из системы
    const handleLogout = () => {
      deleteCookie('authToken'); // Удаляем токен из куки
      alert('Вы успешно вышли из системы.');
      window.location.href = '/login'; // Перенаправляем на страницу входа
    };
  
    const handleAddBalance = (amount) => {
      setBalance(balance + amount);
      setPopupOpen(false);
    };
  

    const handleCopy = (emailId, value, field) => {
      if (!navigator.clipboard) {
          console.error("Clipboard API недоступен");
          return;
      }
  
      navigator.clipboard.writeText(value).then(() => {
          setCopyStatus(prev => ({
              ...prev,
              [emailId]: { ...prev[emailId], [field]: true },
          }));
  
          setTimeout(() => {
              setCopyStatus(prev => ({
                  ...prev,
                  [emailId]: { ...prev[emailId], [field]: false },
              }));
          }, 2000);
      }).catch(err => console.error("Ошибка копирования:", err));
  };
  
  
    

  const handleTextCopy = (emailId, email) => {
    navigator.clipboard.writeText(email);
    setCopyStatus((prev) => ({ ...prev, [emailId]: true }));
  
    setTimeout(() => {
      setCopyStatus((prev) => ({ ...prev, [emailId]: false }));
    }, 2000);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(emails.length / rowsPerPage)) {
        setCurrentPage(page);
    }
};


  // const handleAddBalance = (amount) => {
  //   setBalance(balance + amount);
  //   setPopupOpen(false);
  // };

  const handlePresetClick = (amount) => {
    setCustomAmount(amount);
  };

  const filterEmailTypes = (type) => {
    setFilterType(type);

    // Update selected email types to only include those in the current filter
    if (type !== 'all') {
      setSelectedEmailTypes((prev) =>
        prev.filter((emailType) =>
          emailTypes.find((email) => email.type === emailType && email.category === type)
        )
      );
    }
  };

  const toggleEmailTypeSelection = (type) => {
    setSelectedEmailTypes(() => {
      if (type === 'any') {
        // Если выбрано "Любая", сбросить выбор других почт
        return ['any'];
      } else {
        // Если выбрана конкретная почта, сбросить "Любая" и оставить только выбранную почту
        return [type];
      }
    });
  };

  
  
  
  const handleQuantityChange = (quantity) => {
    if (quantity > 0) setEmailQuantity(quantity);
  };
  
  const orderEmail = async ({ token, site, domain }) => {
    try {
      // Формируем параметры запроса
      const queryParams = new URLSearchParams({
        token,
        site,
        domain,
      });
  
      // Логируем полный URL
      const requestUrl = `https://api.anymessage.shop/email/order?${queryParams.toString()}`;
      console.log('Запрос на сервер:', requestUrl);
  
      // Выполняем запрос
      const response = await fetch(requestUrl, {
        method: 'GET',
      });
  
      // Обрабатываем ответ
      const data = await response.json();
  
      if (response.ok) {
        console.log('Ответ сервера:', data);
        alert('Почта успешно заказана!');
      } else {
        console.error('Ошибка сервера:', data);
        alert(`Ошибка: ${data.message || 'Не удалось заказать почту'}`);
      }
    } catch (err) {
      console.error('Ошибка запроса:', err.message);
      alert('Ошибка запроса. Проверьте соединение и попробуйте снова.');
    }
  };
  

  const handleBuyEmail = async () => {
    const token = getCookie('authToken');
    if (!token) {
        alert('Вы не авторизованы. Пожалуйста, войдите в систему.');
        console.log('Ошибка: отсутствует токен авторизации.');
        return;
    }

    const domain = selectedEmailTypes[0];
    const site = siteName.trim();
    if (!site || !domain) {
        alert('Укажите корректный сайт и выберите тип почты.');
        console.log('Ошибка: отсутствует сайт или домен.');
        return;
    }

    console.log(`📩 Покупаем ${emailQuantity} email-ов для ${site}`);

    let successCount = 0;
    let failedCount = 0;
    let newEmails = [];

    for (let i = 0; i < emailQuantity; i++) {
        const url = new URL('https://api.anymessage.shop/email/order');
        url.searchParams.append('token', token);
        url.searchParams.append('site', site);
        url.searchParams.append('domain', domain);

        console.log(`➡️ Отправляем запрос #${i + 1}: ${url.toString()}`);

        try {
            const response = await fetch(url.toString(), { method: 'GET' });

            console.log(`✅ Ответ #${i + 1} от сервера, статус: ${response.status}`);

            const data = await response.json();
            console.log(`📩 JSON-ответ #${i + 1}:`, data);

            if (data.status === 'success') {
                successCount++;
                newEmails.push({ id: data.id, site, email: data.email, status: "wait", message: null });
            } else {
                failedCount++;
                console.log(`❌ Ошибка заказа #${i + 1}: ${data.value}`);
            }
        } catch (error) {
            failedCount++;
            console.error(`❌ Ошибка при запросе #${i + 1}:`, error);
        }
    }

    // Если есть успешные заказы, добавляем в список
    if (newEmails.length > 0) {
        setEmails(prevEmails => [...newEmails, ...prevEmails]);
        alert(`✅ Успешно заказано ${successCount} email-ов.`);
    } else {
        alert(`❌ Все ${failedCount} заказов не удались.`);
    }
};


const handleUpdateStatus = async (emailId) => {
  console.log(`🔄 Обновляем статус письма ID: ${emailId}...`);
  setUpdatingStatusId(emailId); // Показываем "⏳ Обновляем статус..."

  await fetchEmailHistory(); // Запрашиваем обновлённые данные о письме

  setEmails(prevEmails =>
    prevEmails.map(email =>
      email.id === emailId
        ? { ...email } // Просто обновляем объект, не меняя статус "ok"
        : email
    )
  );

  setUpdatingStatusId(null); // Убираем "⏳ Обновляем статус..."
};




const handleViewMessage = (emailId) => {
  const email = emails.find(e => e.id === emailId);
  if (!email || !email.message) {
    alert("Сообщение пока недоступно.");
    return;
  }

  console.log(`📩 Открываем письмо ID ${emailId}`);
  const messageWindow = window.open("", "_blank", "width=800,height=600");
  messageWindow.document.open();
  messageWindow.document.write(email.message);
  messageWindow.document.close();
};



const handleCancelEmail = async (emailId) => {
  const token = getCookie('authToken');
  if (!token) {
      alert('Вы не авторизованы. Пожалуйста, войдите в систему.');
      console.log('Ошибка: отсутствует токен авторизации.');
      return;
  }

  const url = new URL('https://api.anymessage.shop/email/cancel');
  url.searchParams.append('token', token);
  url.searchParams.append('id', emailId);

  console.log('Отправляем запрос на отмену почты:', url.toString());

  try {
      const response = await fetch(url.toString(), { method: 'GET' });
      const data = await response.json();
      console.log('Ответ от сервера:', data);

      if (data.status === 'success') {
          alert('Почта успешно отменена');
          setEmails((prevEmails) => prevEmails.filter(email => email.id !== emailId));
      } else {
          alert(`Ошибка: ${data.value}`);
          console.log(`Ошибка отмены почты: ${data.value}`);
      }
  } catch (error) {
      console.error('Ошибка при отмене почты:', error);
      alert('Не удалось выполнить запрос. Проверьте подключение.');
  }
};

  const displayedEmails = emails.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div className="personal-cabinet">
      <HeaderCabinet
        balance={balance}
        onAddBalance={handleAddBalance}
        onPopupToggle={() => setPopupOpen(!isPopupOpen)}
      />

      {isPopupOpen && (
        <div
          className="popup-overlay balance-popup-overlay"
          onClick={() => {
            setPopupOpen(false);
            setCustomAmount('');
          }}
        >
          <div
            className="popup-content balance-popup-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-btn" onClick={() => setPopupOpen(false)}>
              &times;
            </button>
            <h2>Пополнение баланса</h2>
            <p>Введите желаемую сумму:</p>
            <input
              type="number"
              value={customAmount}
              onChange={(e) => setCustomAmount(e.target.value)}
              placeholder="Введите сумму"
              className="balance-popup-input"
            />
            <div className="preset-buttons">
              <button onClick={() => handlePresetClick(10)}>+ $10</button>
              <button onClick={() => handlePresetClick(20)}>+ $20</button>
              <button onClick={() => handlePresetClick(50)}>+ $50</button>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => handleAddBalance(Number(customAmount))}
              disabled={!customAmount || Number(customAmount) <= 0}
            >
              Пополнить
            </button>
          </div>
        </div>
      )}

<EmailPurchase
  siteName={siteName}
  setSiteName={setSiteName}
  emailTypeName={emailTypeName}
  setEmailTypeName={setEmailTypeName}
  selectedEmailTypes={selectedEmailTypes}
  setSelectedEmailTypes={setSelectedEmailTypes}
  emailQuantity={emailQuantity}
  setEmailQuantity={setEmailQuantity}
  filterType={filterType}
  setFilterType={setFilterType}
  emailTypes={emailTypes}
  toggleEmailTypeSelection={toggleEmailTypeSelection}
  handleBuyEmail={handleBuyEmail}
/>



<section className="received-emails">
      <h2>{t('title')}</h2>
      <div className="email-table">
        <div className="table-header">
          <div className="mobile-details">
            <div className="id-cell">{t('table.id')}</div>
            <div className="site-cell">{t('table.site')}</div>
            <div className="email-cell">{t('table.email')}</div>
            <div className="status-cell">{t('table.status')}</div>
          </div>
          <div className="mobile-actions">
            <div>{t('table.actions')}</div>
          </div>
        </div>

        {isLoading && emails.length === 0 ? (
  <div className="table-row empty">
    <div className="mobile-details">
      <div className="skeleton-box"></div>
    </div>
  </div>
) : emails.length === 0 ? (
  <div className="table-row empty">
    <div className="mobile-details">
      <p className="empty-message">{t('no_emails')}</p>
    </div>
  </div>
) : (
  emails.map((email) => (
    <div className="table-row" key={email.id}>
      <div className="mobile-details">
        <div className="id-cell">
          <span onClick={() => handleCopy(email.id, email.id, 'id')}>
            {email.id}
          </span>

          <button className="copy-btn" onClick={() => handleCopy(email.id, email.id, 'id')}>
            <img 
              src={copyStatus[email.id]?.id ? "../small-check.svg" : "../copy.svg"} 
              alt="Copy" 
              className="copy-icon" 
              key={copyStatus[email.id]?.id ? "checked" : "copy"} // 🛠️ Принудительный ререндер
            />
          </button>
        </div>
        <div className="service-cell">
          <span onClick={() => handleCopy(email.id, email.site, 'site')}>{email.site}</span>
        </div>
        <div className="email-cell">
          <span onClick={() => handleCopy(email.id, email.email, 'email')}>
            {email.email}
          </span>

          <button className="copy-btn" onClick={() => handleCopy(email.id, email.email, 'email')}>
            <img 
              src={copyStatus[email.id]?.email ? "../small-check.svg" : "../copy.svg"} 
              alt="Copy" 
              className="copy-icon"
              key={copyStatus[email.id]?.email ? "checked-email" : "copy-email"} // Принудительный ререндер
            />
          </button>
        </div>

        <div className="status-cell">
          {email.status === "wait" ? (
            <div className="status-wait">
              <button 
                className="refresh-status-btn" 
                onClick={() => handleUpdateStatus(email.id)} 
                disabled={updatingStatusId === email.id}
              >
                {updatingStatusId === email.id ? "⏳ " + t('status.updating') : "🔄 " + t('status.waiting')}
              </button>
            </div>
          ) : email.status === "cancel" ? ( 
            <span>❌ {t('status.cancelled')}</span> // Показываем "❌ Отменено"
          ) : (
            <span>{email.status === "ok" ? `✔️ ${t('status.ready')}` : email.status}</span> // Показываем "✔️ Готово", но статус остаётся "ok"
          )}
        </div>
      </div>
      <div className="mobile-actions">
        <button 
          className="view-btn" 
          onClick={() => handleViewMessage(email.id)} 
          disabled={email.status !== "ok" || !email.message} // ✅ Теперь кнопка активна, если письмо есть
        >
          👁
        </button>
        <button className="cancel-btn" onClick={() => handleCancelEmail(email.id)}>❌</button>
      </div>
    </div>
  ))
)}


      </div>

      <button 
  onClick={() => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
      fetchEmailHistory(currentPage - 1);
    }
  }} 
  disabled={currentPage === 1}
>
  ⬅️ Назад
</button>

<button 
  onClick={() => {
    if (hasNextPage) {
      setCurrentPage(prevPage => prevPage + 1);
      fetchEmailHistory(currentPage + 1);
    }
  }} 
  disabled={!hasNextPage}
>
  Вперёд ➡️
</button>

    </section>
      <SupportButton />
    </div>
  );
};

export default PersonalCabinet;
