import React from 'react';
import './EmailServices.css';

import defaultLogo from '../../assets/mail-logo/default-logo.png';
import gmailLogo from '../../assets/mail-logo/gmail-logo.png';
import yahooLogo from '../../assets/mail-logo/yahoo-logo.png';
import outlookLogo from '../../assets/mail-logo/outlook-logo.png';
import protonMailLogo from '../../assets/mail-logo/protonmail-logo.png';
import yandexLogo from '../../assets/mail-logo/yandex-logo.png';
import iCloudLogo from '../../assets/mail-logo/icloud-logo.png';

export const services = [
  { name: 'Gmail', price: '$5', image: gmailLogo },
  { name: 'Yahoo', price: '$3', image: yahooLogo },
  { name: 'Outlook', price: '$4', image: outlookLogo },
  { name: 'ProtonMail', price: '$7', image: protonMailLogo },
  { name: 'Zoho Mail', price: '$2', image: defaultLogo },
  { name: 'Mail.ru', price: '$1', image: defaultLogo },
  { name: 'Yandex', price: '$2', image: yandexLogo },
  { name: 'iCloud', price: '$4', image: iCloudLogo },
  { name: 'FastMail', price: '$6', image: defaultLogo },
  { name: 'GMX', price: '$3', image: defaultLogo },
  { name: 'AOL', price: '$3', image: defaultLogo },
  { name: 'Tutanota', price: '$5', image: defaultLogo },
];

const EmailServices = () => {
  return (
    <section className="email-services section-container">
      <h2>Доступные почтовые сервисы</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div>
              <div className="placeholder-logo">
                <img
                  src={service.image || defaultLogo} // Используем изображение или дефолтное
                  alt={`${service.name} logo`}
                  className="service-logo"
                />
              </div>
              <p className="service-name">{service.name}</p>
              <p className="service-price">{service.price}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EmailServices;
