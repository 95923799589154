import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookie, deleteCookie } from '../../utils/cookies';
import AuthPopup from '../AuthPopup/AuthPopup'; // Компонент AuthPopup
import { useUser } from '../../context/UserContext'; // Теперь используем UserContext
import { useLocation } from 'react-router-dom'; // Импортируем useLocation
import './Header.css';


const Header = () => {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthPopupOpen, setAuthPopupOpen] = useState(false);
  const [isLoginPopup, setIsLoginPopup] = useState(true); // Переключение между входом и регистрацией
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Статус авторизации
  const [customAmount, setCustomAmount] = useState(''); // Для пополнения баланса
  // const [isPopupOpen, setPopupOpen] = useState(false); // Для попапа пополнения

  // Теперь получаем всю информацию о пользователе
  const {user, fetchUserInfo, isPopupOpen, setPopupOpen } = useUser(); 

  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
  const languages = [
    { code: 'en', label: '🇺🇸 English' },
    { code: 'ru', label: '🇷🇺 Русский' },
    { code: 'zh', label: '🇨🇳 中文 (简体)' },  // Китайский (упрощённый)
    { code: 'es', label: '🇪🇸 Español' }      // Испанский
    
  ];

  const toggleLangDropdown = () => {
    setIsLangDropdownOpen(prev => !prev);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Меняем язык
    setIsLangDropdownOpen(false); // Закрываем дропдаун после выбора
  };

  const langDropdownRef = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (langDropdownRef.current && !langDropdownRef.current.contains(event.target)) {
      setIsLangDropdownOpen(false); // Закрываем дропдаун
    }
  };

  if (isLangDropdownOpen) {
    document.addEventListener("mousedown", handleClickOutside);
  } else {
    document.removeEventListener("mousedown", handleClickOutside);
  }

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [isLangDropdownOpen]);

  

   // Проверяем авторизацию при загрузке
   useEffect(() => {
    const token = getCookie('authToken');
    if (token) {
      setIsLoggedIn(true);
      fetchUserInfo(); // Загружаем все данные пользователя
    }
  }, []);

  // Обработчик выхода
  const handleLogout = () => {
    deleteCookie('authToken'); // Удаляем токен
    setIsLoggedIn(false); // Сбрасываем статус авторизации
    alert('Вы успешно вышли из системы.');
  
    // Проверяем текущий URL
    if (window.location.pathname === '/') {
      // Если пользователь уже на главной странице, просто обновляем интерфейс
      return;
    }
  
    // Если пользователь находится на другой странице, перенаправляем на главную
    window.location.href = '/';
  };

  const handleAddBalance = () => {
    if (customAmount && Number(customAmount) > 0) {
      fetchUserInfo(); // ⬅️ Запрашиваем обновлённые данные с сервера
      setPopupOpen(false);
      setCustomAmount('');
    }
  };

  const location = useLocation(); // Получаем текущий URL
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Состояние дропдауна

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <header className="homepage-header">
  <div className="logo">
    <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
      <h1 className="header-logo">AnyMessage</h1>
    </a>
  </div>

  <div className="header-controls">

    {/* Кнопка смены языка */}
    <div className="language-switcher">
      <button className="lang-btn" onClick={toggleLangDropdown} title={t('header.language')}>
        🌍
      </button>
      {isLangDropdownOpen && (
        <div ref={langDropdownRef} className="lang-dropdown">
          {languages.map((lang) => (
            <button key={lang.code} onClick={() => changeLanguage(lang.code)} className="lang-option">
              {lang.label}
            </button>
          ))}
        </div>
      )}
    </div>

    {isLoggedIn ? (
      <>
        <div className="dual-button">
          {/* Если на главной – просто переход в личный кабинет */}
          {location.pathname === '/' ? (
            <button 
              className="profile-btn" 
              onClick={() => window.location.href = "/personalCabinet"}
              title={t('header.personal_cabinet')} // Подсказка
            >
              👤
            </button>
          ) : (
            /* Если в личном кабинете – показываем дропдаун */
            <div className="profile-dropdown">
              <button className="profile-btn" onClick={toggleDropdown} title={t('header.settings')}>
                👤
              </button>
              {isDropdownOpen && (
                <div className="dropdown-content">
                  <a href="/settings">{t('header.settings')}</a>
                  <a href="/help">{t('header.help')}</a>
                </div>
              )}
            </div>
          )}

          {/* Правая кнопка – баланс */}
          <button className="balance-btn" onClick={() => setPopupOpen(true)}>
            {user?.balance === undefined ? (
              <span className="skeleton"></span> // Скелетон до загрузки
            ) : (
              `USD: ${user.balance}` // Баланс после загрузки
            )}
          </button>
        </div>

        {/* Кнопка "Выйти" */}
        <button className="btn btn-secondary full-screen" onClick={handleLogout}>
          {t('header.logout')}
        </button>
      </>
    ) : (
      <>
        {/* Кнопки для неавторизованных пользователей */}
        <button
          className="btn btn-secondary"
          onClick={() => {
            setIsLoginPopup(false);
            setAuthPopupOpen(true);
          }}
        >
          {t('header.register')}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            setIsLoginPopup(true);
            setAuthPopupOpen(true);
          }}
        >
          {t('header.login')}
        </button>
      </>
    )}
  </div>

  {/* Затемняющий фон (бекдроп) */}
  {isMenuOpen && <div className="menu-overlay active" onClick={() => setIsMenuOpen(false)}></div>}

  {/* Кнопка открытия меню */}
  <button
    className={`burger-menu ${isMenuOpen ? 'open' : ''}`}
    onClick={toggleMenu}
  >
    ☰
  </button>

  {/* Выпадающее меню */}
  {isMenuOpen && (
    <div className={`dropdown-menu ${isMenuOpen ? 'open' : ''}`}>
      <a href="/api"><span className="icon">📡 </span> {t('header.api')}</a>
      <a
        href="https://t.me/anymessage_shop_bot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon">🤖 </span> 
        {t('header.telegram_bot')}
      </a>
      {isLoggedIn && (
        <button className="btn btn-secondary exit-mobile" onClick={handleLogout}>
          {t('header.logout')}
        </button>
      )}
    </div>
  )}

  {/* Попап для авторизации */}
  <AuthPopup
    isOpen={isAuthPopupOpen}
    onClose={() => setAuthPopupOpen(false)}
    isLogin={isLoginPopup}
    onLoginSuccess={() => {
      setIsLoggedIn(true);
      fetchUserInfo(); // ⬅️ Загружаем данные пользователя
      setAuthPopupOpen(false);
    }}
  />

  {/* Попап для пополнения баланса */}
  {isPopupOpen && (
    <div className="popup-overlay" onClick={() => setPopupOpen(false)}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-btn" onClick={() => setPopupOpen(false)}>
          &times;
        </button>
        <h2>{t('header.balance_top_up')}</h2>
        <input
          type="number"
          value={customAmount}
          onChange={(e) => setCustomAmount(e.target.value)}
          placeholder={t('header.enter_amount')}
        />
        <button
          className="btn btn-primary"
          onClick={handleAddBalance}
          disabled={!customAmount || Number(customAmount) <= 0}
        >
          {t('header.top_up')}
        </button>
      </div>
    </div>
  )}
</header>
  );
};

export default Header;
