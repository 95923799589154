import React, { useState, useEffect, useRef } from 'react';
import './EmailPurchase.css';
import { useUser } from '../../context/UserContext';  // Импортируем контекст
import { setCookie, getCookie } from '../../utils/cookies'; // Для работы с куки
import ActivationPopup from '../ActivationPopup/ActivationPopup';
import CopyButton from '../CopyButton/CopyButton';
import { useTranslation } from 'react-i18next';






const EmailPurchase = ({
  siteName,
  setSiteName,
  emailTypeName,
  setEmailTypeName,
  selectedEmailTypes,
  setSelectedEmailTypes,
  emailQuantity,
  setEmailQuantity,
  filterType,
  setFilterType,
  emailTypes,
  toggleEmailTypeSelection,
  handleBuyEmail,
  clearSelections,
}) => {
  const { t } = useTranslation('email_purchases');

const [filteredByInput, setFilteredByInput] = useState(emailTypes);

const {user, fetchUserInfo, isPopupOpen, setPopupOpen } = useUser(); 
const [userId, setUserId] = useState(0);
const [userToken, setUserToken] = useState(0);
const [isLoadingEmails, setIsLoadingEmails] = useState(false);
const [lastSearched, setLastSearched] = useState(() => getCookie('lastSearchedSite') || ''); // ✅ Загружаем из `cookies`
const siteNameRegex = /^(?!-)[A-Za-z0-9-]{1,63}\.[A-Za-z]{2,}$/;
const [siteNameError, setSiteNameError] = useState(""); // Ошибка ввода сайта
const [emailTypeError, setEmailTypeError] = useState(""); // Ошибка при выборе почты
const [shakeEffect, setShakeEffect] = useState(false); // Анимация тряски инпута
const [typingTimer, setTypingTimer] = useState(null); // ✅ Таймер для `debounce` 
const [isActivationPopupOpen, setActivationPopupOpen] = useState(false);
const [activationMessage, setActivationMessage] = useState('');
const [errorMessage, setErrorMessage] = useState(''); // Ошибки
const [activationCode, setActivationCode] = useState('');
const [isSendingCode, setSendingCode] = useState(false);
const [canResend, setCanResend] = useState(true);


const handleEmailTypeSelectionClick = () => {
  if (!siteName.trim() || siteNameError) {
    setShakeEffect(true); // Включаем анимацию тряски
    setTimeout(() => setShakeEffect(false), 500); // Отключаем через 0.5 сек
    return;
  }
};

useEffect(() => {
  const savedSite = getCookie('lastSearchedSite'); // ✅ Получаем сохранённый сайт
  if (savedSite) {
    setLastSearched(savedSite); // ✅ Устанавливаем сохранённое значение
  }
}, []);

// Открывать попап, если аккаунт не активирован при входе на страницу
useEffect(() => {
  if (user && !user.active) {
      setActivationPopupOpen(true);
      setActivationMessage(t('activation.message.code_sent', { email: user.email }));
  }
}, [user]); // Срабатывает при изменении `user`

const handleSiteNameChange = (e) => {
  const value = e.target.value.trim();
  setSiteName(value); // ✅ Позволяем свободный ввод
  setSiteNameError(""); // ✅ Убираем ошибку во время ввода

  // ✅ Если пользователь вводит текст — сбрасываем предыдущий таймер
  if (typingTimer) clearTimeout(typingTimer);

  // ✅ Запускаем новый таймер на 1 секунду (1000 мс)
  setTypingTimer(setTimeout(() => validateAndFormatSite(value), 450));
};

const validateAndFormatSite = (value) => {
  let formattedValue = value.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/$/, ""); // ✅ Очищаем `https://`, `www.` и `/`

  if (!siteNameRegex.test(formattedValue)) {
    setSiteNameError("❌ Введите корректный домен без `https://` и `www.`"); // ✅ Показываем ошибку
    setShakeEffect(true);
    setTimeout(() => setShakeEffect(false), 500); // ✅ Потряхивание
  } else {
    setSiteName(formattedValue); // ✅ Устанавливаем исправленный домен
    setSiteNameError(""); // ✅ Ошибок нет
    setCookie('lastSearchedSite', formattedValue, 7); // ✅ Запоминаем в `cookies`
  }
};

useEffect(() => {
  if (siteName.trim()) {
    setEmailTypeError(""); // Сбрасываем ошибку, если сайт введён
  }
}, [siteName]);

useEffect(() => {
  if (siteName.trim() === "") {
    setAvailableEmailTypes([]); // Очищаем список почт, если поле пустое
    setSiteNameError(""); // Сбрасываем ошибку
    return;
  }

  if (!siteNameRegex.test(siteName.trim())) {
    setSiteNameError("Неправильно введено название сайта"); // Показываем ошибку
    return;
  }

  setSiteNameError(""); // Ошибки нет, можно искать
  setIsLoadingEmails(true); // Показываем "Загрузка доступных почт..."

  const timeoutId = setTimeout(() => {
    fetchAvailableEmailTypes(siteName.trim());
    setLastSearched(siteName.trim()); // ✅ Запоминаем последний введённый сайт
    setCookie('lastSearchedSite', siteName.trim(), 7); // ✅ Сохраняем в `cookies` на 7 дней
  }, 700); // Debounce

  return () => clearTimeout(timeoutId);
}, [siteName]);



  useEffect(() => {
    console.log("Статус попапа изменился:", isPopupOpen);
  }, [isPopupOpen]);

  useEffect(() => {
    const minPrice = Math.min(...emailTypes.map((email) => email.price));
    setEmailTypeName(`Любая (от ${minPrice}$)`);
    toggleEmailTypeSelection('any');
  }, []);

  const fillService = (service) => {
    const formattedService = service.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/$/, "");
    setSiteName(formattedService);
    setSiteNameError(""); // ✅ Убираем ошибки
    setLastSearched(formattedService);
    setCookie('lastSearchedSite', formattedService, 7);
  };

  const handleQuantityChange = (value) => {
    if (!isNaN(value)) {
      const quantity = Math.max(1, Math.min(20, value)); // Ограничение значений от 1 до 20
      setEmailQuantity(quantity);
    }
  };

  const selectEmailType = (type) => {
    setEmailTypeName(type);
  };

  const filterEmailTypes = (type) => {
    setFilterType(type);

    if (type !== 'all') {
      setFilteredByInput(
        emailTypes.filter((email) => email.category === type)
      );
    } else {
      setFilteredByInput(emailTypes);
    }
  };

  const handleEmailTypeInputChange = (e) => {
    const inputValue = e.target.value;
    setEmailTypeName(inputValue);

    if (inputValue.trim()) {
      setFilterType('all'); // Сбросить фильтр на "все"
      setFilteredByInput(
        emailTypes.filter((email) =>
          email.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    } else {
      filterEmailTypes(filterType); // Вернуть текущий фильтр
    }
  };

  const [availableEmailTypes, setAvailableEmailTypes] = useState([]); // Доступные почты
  const [isLoading, setIsLoading] = useState(false); // Индикатор загрузки почт
  const [error, setError] = useState(null); // Ошибки при загрузке почт

  const calculatePrice = () => {
    // Если нет выбранных типов почты или доступных почт
    if (!selectedEmailTypes.length || !availableEmailTypes.length) {
      return 0; // Возвращаем 0
    }
  
    // Находим выбранный тип почты
    const selectedType = availableEmailTypes.find(
      (emailType) => emailType.type === selectedEmailTypes[0]
    );
  
    // Если тип почты не найден, возвращаем 0
    if (!selectedType) {
      console.error('Выбранный тип почты не найден в доступных почтах:', selectedEmailTypes[0]);
      return 0;
    }
  
    // Расчет итоговой стоимости
    const totalPrice = selectedType.price * emailQuantity;
  
    return totalPrice.toFixed(4); // Возвращаем с округлением до двух знаков
  };

  

     // Функция для отправки кода на почту при нажатии на "Активировать"
     const handleOpenActivationPopup = async () => {
      const token = getCookie('authToken');
      if (!token || !user?.email) {
          setErrorMessage(t('activation.error.auth'));
          return;
      }

      setActivationPopupOpen(true);
      setActivationMessage(t('activation.message.code_sent', { email: user.email }));
      setErrorMessage('');

      await handleRequestActivationCode();
  };

  // Функция запроса нового кода
  const handleRequestActivationCode = async () => {
      const token = getCookie('authToken');
      if (!token) return;

      setSendingCode(true);
      setErrorMessage('');
      setActivationMessage(t('activation.message.sending_code', { email: user.email }));

      try {
          const response = await fetch(`https://api.anymessage.shop/user/sendnewcode?token=${token}`, {
              method: 'GET',
          });

          const data = await response.json();
          console.log('📩 Ответ сервера:', data);

          if (data.status === 'success') {
              setActivationMessage(t('activation.message.code_sent', { email: user.email }));
              setErrorMessage('');
              setCanResend(false);
              setTimeout(() => setCanResend(true), 60000);
          } else {
              setErrorMessage(t('activation.error.general', { error: data.value }));
              setActivationMessage('');
          }
      } catch (error) {
          console.error('Ошибка при отправке кода:', error);
          setErrorMessage(t('activation.error.connection'));
      }

      setSendingCode(false);
  };

  // Функция отправки кода на сервер
  const handleActivateAccount = async () => {
      const token = getCookie('authToken');
      if (!token) return;

      if (!activationCode.trim()) {
          setErrorMessage(t('activation.error.empty_code'));
          return;
      }

      try {
          const response = await fetch(`https://api.anymessage.shop/user/activate?token=${token}&code=${activationCode}`, {
              method: 'GET',
          });

          const data = await response.json();
          console.log('🔓 Ответ активации:', data);

          if (data.status === 'success') {
              setActivationMessage(t('activation.message.success'));
              setErrorMessage('');
              setUser(prev => ({ ...prev, active: true }));

              // Закрываем попап через 3 сек (если нужно)
              setTimeout(() => setActivationPopupOpen(false), 3000);
          } else {
              setErrorMessage(t('activation.error.general', { error: data.value }));
              setActivationMessage('');
          }
      } catch (error) {
          console.error('Ошибка при активации:', error);
          setErrorMessage(t('activation.error.connection'));
      }
  };

  // Функция для получения доступных почт
const fetchAvailableEmailTypes = async (site) => {
  if (!site.trim()) {
      console.log('Название сайта не указано.');
      return;
  }

  const token = getCookie('authToken'); // Получаем токен из куки
  if (!token) {
      console.error('Токен отсутствует. Авторизуйтесь.');
      setError('Вы не авторизованы. Пожалуйста, войдите.');
      return;
  }

  setIsLoadingEmails(true);
  setError(null);

  try {
      const url = `https://api.anymessage.shop/email/quantity?token=${token}&site=${site}`;
      console.log('Запрос на сервер:', url);

      const response = await fetch(url);
      const data = await response.json();

      if (response.ok && data.status === 'success') {
          console.log('Успешный ответ сервера:', data);

          const emailTypes = Object.entries(data.data).map(([domain, info]) => ({
              type: domain,
              count: info.count,
              price: info.price,
          }));

          setAvailableEmailTypes(emailTypes);

          if (emailTypes.length > 0) {
              setSelectedEmailTypes([emailTypes[0].type]);
              setEmailTypeName(emailTypes[0].type);
          }
      } else {
          console.error('Ошибка сервера:', data.message || 'Не удалось получить данные');

          // ✅ Проверяем, если сервер вернул ошибку из-за неактивированного аккаунта
          if (data.status === 'error' && data.value === 'token') {
            console.error("check",data.status);
              setError('Ваш аккаунт не активирован. Мы не можем показать доступные почты, пока вы не активируете аккаунт.');
          } else {
              setError(data.message || 'Не удалось получить данные');
          }

          setAvailableEmailTypes([]); // Очищаем список почт
      }
  } catch (err) {
      console.error('Ошибка соединения:', err.message);
      setError('Ошибка соединения с сервером.');
      setAvailableEmailTypes([]); // Очищаем список почт в случае ошибки
  } finally {
      setIsLoadingEmails(false);
  }
};


  useEffect(() => {
    console.log('Доступные почты обновлены:', availableEmailTypes);
  }, [availableEmailTypes]);

  return (
<section className="buy-email">
      <h2>{t('email_purchases.title')}</h2>
      <div className="email-purchase-container">

        <div className="service-selection-group">
          {/* Блок с информацией о пользователе */}
          <div className="user-info-bar">
            <div className="user-icon-placeholder"></div>
            <div className="user-details">
              <p>
                <strong>{t('email_purchases.user_info.id')}</strong>
                <span className="status-icon" title={
                  user === null
                    ? t('email_purchases.user_info.loading')
                    : user?.active
                    ? t('email_purchases.user_info.active')
                    : t('email_purchases.user_info.inactive')
                }>
                  {user === null ? '⏳' : (
                    // <button onClick={handleOpenActivationPopup} className="activate-btn shake">
                    //             {t('activation.button')}
                    //        </button>
                    <>
                        {user?.active ? (
                          `${user?.id ?? 'N/A'}✅`
                        ) : 
                        (
                        <>
                            {user?.id ?? 'N/A'}❌
                            <button onClick={handleOpenActivationPopup} className="activate-btn shake">
                               {t('activation.button')}
                            </button>
                        </>
                        )}
                    </>
                )}

                </span>
              </p>

              <p>
                <strong>{t('email_purchases.user_info.balance')}</strong>
                <span className="clickable-balance" onClick={() => setPopupOpen(true)}>
                  {user?.balance === undefined ? t('email_purchases.user_info.loading') : `$${user.balance}`}➕
                </span>
              </p>

              <p className="token-container">
                <strong>{t('email_purchases.user_info.token')}</strong>
                <CopyButton text={user?.token || t('email_purchases.user_info.loading')} />
              </p>
            </div>
          </div>

          <div className="service-selection">
            <label>
              <h3>{t('email_purchases.site_input.title')}</h3>
              <div className="input-container">
                <input
                  type="text"
                  value={siteName}
                  onChange={handleSiteNameChange} // ✅ Разрешаем свободный ввод
                  placeholder={t('email_purchases.site_input.placeholder')}
                  required
                  className={`${siteNameError ? "input-error" : ""} ${shakeEffect ? "shake" : ""}`.trim()}
                />
                {siteName && (
                  <button type="button" className="clear-input-btn" onClick={() => setSiteName('')}>
                    {t('email_purchases.site_input.clear')}
                  </button>
                )}
                {siteNameError && <p className="error-message">{siteNameError}</p>}
              </div>
            </label>


            <div className="popular-services">
              <button 
                type="button" 
                className="last-searched-btn" 
                onClick={() => setSiteName(lastSearched || 'yandex.com')}
              >
                {lastSearched || 'Yandex'}
              </button>

              <button type="button" onClick={() => fillService('instagram.com')}>
                {t('email_purchases.site_input.popular_services.instagram')}
              </button>
              <button type="button" onClick={() => fillService('facebook.com')}>
                {t('email_purchases.site_input.popular_services.facebook')}
              </button>
              <button type="button" onClick={() => fillService('gmail.com')}>
                {t('email_purchases.site_input.popular_services.gmail')}
              </button>
              <button type="button" onClick={() => fillService('icloud.com')}>
                {t('email_purchases.site_input.popular_services.icloud')}
              </button>
              <button type="button" onClick={() => fillService('steam.com')}>
                {t('email_purchases.site_input.popular_services.steam')}
              </button>
              <button type="button" onClick={() => fillService('spotify.com')}>
                {t('email_purchases.site_input.popular_services.spotify')}
              </button>
            </div>
          </div>
        </div>

        <div className="service-group">
          <div className={`email-type-selection ${!siteName.trim() || siteNameError ? "disabled" : ""}`} onClick={handleEmailTypeSelectionClick}>
            <label>
              <h3>{t('email_purchases.email_type_selection.title')}</h3>
              <div className="input-container">
                <input
                  type="text"
                  value={
                    siteName.trim() === ""
                      ? "..."
                      : isLoadingEmails
                      ? "..."
                      : availableEmailTypes.length === 0
                      ? t('email_purchases.email_type_selection.no_email_types')
                      : emailTypeName
                  }
                  placeholder={t('email_purchases.email_type_selection.placeholder')}
                  required
                  readOnly
                />
              </div>
            </label>
            {isLoading && <p>{t('email_purchases.email_type_selection.loading')}</p>}
            {error && <p className="error-message">{error}</p>}

              <div className={`email-types-scroll ${siteName.trim() !== "" ? "expanded" : ""}`}>
                {user?.active ? (
                    // ✅ Если аккаунт активирован, показываем доступные почты
                    availableEmailTypes.length > 0 ? (
                        availableEmailTypes.map((emailType) => (
                            <button
                                key={emailType.type}
                                type="button"
                                className={`email-type-btn ${selectedEmailTypes.includes(emailType.type) ? 'selected' : ''}`}
                                onClick={() => {
                                    setSelectedEmailTypes([emailType.type]);
                                    setEmailTypeName(emailType.type);
                                }}
                            >
                                <img src="../default-logo.svg" alt={emailType.type} className="email-icon" />
                                {emailType.type} (${emailType.price})
                            </button>
                        ))
                    ) : (
                        <p className="no-emails-message">{t('email_purchases.email_type_selection.no_emails_message')}</p>
                    )
                ) : (
                    // ❌ Если аккаунт не активирован, показываем ошибку и кнопку
                    <>
                        <p className="no-emails-message">
                            ❌ {t('email_purchases.email_type_selection.account_not_activated')}
                        </p>
                        <button onClick={handleOpenActivationPopup} className="activate-btn shake">
                            {t('activation.button')}
                        </button>
                    </>
                )}
            </div>
          </div>

          <div className={`purchase-actions ${!siteName.trim() || siteNameError ? "disabled" : ""}`} onClick={handleEmailTypeSelectionClick}>
            <h3>{t('email_purchases.purchase.title')}</h3>
            <div className="quantity-control">
              <button type="button" className="quantity-btn" onClick={() => handleQuantityChange(emailQuantity - 1)} disabled={emailQuantity <= 1}>
                {t('email_purchases.purchase.decrease')}
              </button>
              <input
                type="number"
                value={emailQuantity}
                min="1"
                max="20"
                onChange={(e) => handleQuantityChange(Number(e.target.value))}
                inputMode="numeric"
                className="quantity-input"
              />
              <button type="button" className="quantity-btn" onClick={() => handleQuantityChange(emailQuantity + 1)} disabled={emailQuantity >= 20 || !siteName || selectedEmailTypes.length === 0}>
                {t('email_purchases.purchase.increase')}
              </button>
            </div>
            <button className="buy-button" type="button" onClick={handleBuyEmail} disabled={!siteName || selectedEmailTypes.length === 0}>
              {t('email_purchases.purchase.buy')}: <span>{calculatePrice()} {t('email_purchases.purchase.price')}</span>
            </button>
          </div>
        </div>
      </div>
         {/* Вызов попапа активации */}
         <ActivationPopup 
                isOpen={isActivationPopupOpen} 
                onClose={() => setActivationPopupOpen(false)} 
                activationMessage={activationMessage}
                errorMessage={errorMessage}
                activationCode={activationCode}
                setActivationCode={setActivationCode}
                onActivate={handleActivateAccount}
                onRequestCode={handleRequestActivationCode}
                isSendingCode={isSendingCode}
                canResend={canResend}
                user={user} // Передаем пользователя для отображения email
          />
    </section>
    
  );
};

export default EmailPurchase;
