import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from '../components/Header/LanguageDropdown';

import './HomePage.css';
// import impgoImage from '../letter.png';
import EmailServices, { services as servicesTo } from '../components/EmailServices/EmailServices'; // Импортируем как servicesTo
import AuthPopup from '../components/AuthPopup/AuthPopup';
import SupportButton from '../components/SupportButton/SupportButton';
import Header from '../components/Header/Header';


const HomePage = () => {

  const [isAuthPopupOpen, setAuthPopupOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isPasswordResetPopupOpen, setPasswordResetPopupOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const whyListRef = useRef(null);
  // Список предустановленных вариантов
 // Локальный список сервисов (servicesFrom)
 const servicesFrom = [
  'instagram.com',
  'facebook.com',
  'youtube.com',
  'gmail.com',
  'yahoo.com',
];
const { t, i18n } = useTranslation();
console.log('Current Language:', i18n.language);
// Состояния для каждого поля
const [selectedFromService, setSelectedFromService] = useState('');
const [selectedToService, setSelectedToService] = useState('');

// Обработчики изменения значений
const handleFromServiceChange = (event) => {
  setSelectedFromService(event.target.value); // Обновляем "От кого"
};

const handleToServiceChange = (event) => {
  setSelectedToService(event.target.value); // Обновляем "Кому"
};
  

  const handleOpenAuthPopup = (loginState) => {
    setIsLogin(loginState);
    setAuthPopupOpen(true);
  };

  const handlePasswordReset = () => {
    alert(`Ссылка для восстановления пароля отправлена на ${resetEmail}`);
    setResetEmail('');
    setPasswordResetPopupOpen(false);
  };

  useEffect(() => {
    const list = whyListRef.current;

    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (list) {
          list.scrollBy({ left: 2, behavior: 'smooth' });
        }
      }, 30);
    };

    const stopAutoScroll = () => {
      clearInterval(scrollInterval);
    };

    startAutoScroll();

    list.addEventListener('mousedown', stopAutoScroll);
    list.addEventListener('touchstart', stopAutoScroll);

    return () => {
      clearInterval(scrollInterval);
      list.removeEventListener('mousedown', stopAutoScroll);
      list.removeEventListener('touchstart', stopAutoScroll);
    };
  }, []);

  return (
    <div className="homepage">
  <Header onLoginClick={() => handleOpenAuthPopup(true)} />

  {/* Контент заголовка */}
  <section className="header-content section-container">
    <div className="header-content-inner">
      <div className="header-text">
        <h1>{t('homepage.title')}</h1>
        <p>{t('homepage.description')}</p>
        <button className="btn btn-primary start-now-btn" onClick={() => handleOpenAuthPopup(true)}>
          {t('homepage.start_now')}
        </button>
      </div>
      <div className="header-image-wrapper">
        {/* <img src="../letter.png" alt="Иллюстрация AnyMessage" className="header-image" /> */}
        <div className="email-overlay">
          <div className="from-service">
            <div className="from-service-main">
              <label htmlFor="email-dropdown">{t('homepage.from')}</label>
              <input
                type="text"
                id="from-service-input"
                list="from-services-list"
                placeholder={t('homepage.from_placeholder')}
                value={selectedFromService}
                onChange={handleFromServiceChange}
              />
              <datalist id="from-services-list">
                {servicesFrom.map((service, index) => (
                  <option key={index} value={service} />
                ))}
              </datalist>
            </div>
            <p className="to-email-info">{t('homepage.from_info')}</p>
          </div>

          <div className="email-fields">
            <div className="to-email">
              <div className="to-email-main">
                <label htmlFor="to-service-input">{t('homepage.to')}</label>
                <select
                  id="to-service-input"
                  value={selectedToService}
                  onChange={handleToServiceChange}
                >
                  {servicesTo.map((service, index) => (
                    <option key={index} value={service.name}>
                      {service.name} — {service.price}
                    </option>
                  ))}
                </select>
              </div>
              <div className="to-email-tip">
                <p className="to-email-info">
                  {t('homepage.to_info')} <br />
                  {selectedFromService && (
                    <>
                      {t('homepage.example_from')} <span className="underlined-service">{selectedFromService}</span>.
                    </>
                  )}
                </p>
              </div>
            </div>
            <button className="btn btn-primary send-email-btn" onClick={() => handleOpenAuthPopup(true)}>
              {t('homepage.get_email')}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  {/* Секция преимуществ */}
  <section className="homepage-why-choose-us section-container">
    <h2>{t('homepage.why_choose_us')}</h2>
    <div className="why-list" ref={whyListRef}>
      {[
        { icon: '💰', title: t('homepage.advantages.low_prices'), text: t('homepage.advantages.low_prices_desc') },
        { icon: '⚡', title: t('homepage.advantages.fast_top_up'), text: t('homepage.advantages.fast_top_up_desc') },
        { icon: '🔒', title: t('homepage.advantages.money_back'), text: t('homepage.advantages.money_back_desc') },
        { icon: '⏰', title: t('homepage.advantages.support_24_7'), text: t('homepage.advantages.support_24_7_desc') },
        { icon: '⚙️', title: t('homepage.advantages.stable_work'), text: t('homepage.advantages.stable_work_desc') },
        { icon: '📊', title: t('homepage.advantages.custom_plans'), text: t('homepage.advantages.custom_plans_desc') },
        { icon: '🖥️', title: t('homepage.advantages.easy_interface'), text: t('homepage.advantages.easy_interface_desc') },
        { icon: '🚀', title: t('homepage.advantages.quick_start'), text: t('homepage.advantages.quick_start_desc') },
        { icon: '🤖', title: t('homepage.advantages.telegram_bot'), text: t('homepage.advantages.telegram_bot_desc') }
      ].map(({ icon, title, text }, index) => (
        <div className="why-item" key={index}>
          <div className="icon">{icon}</div>
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      ))}
    </div>
  </section>

  {/* Секция почтовых сервисов */}
  <EmailServices />

  {/* Секция отзывов */}
  <section className="homepage-reviews section-container">
    <h2>{t('homepage.reviews.title')}</h2>
    <div className="reviews-list">
      <div className="review-item">
        <p>{t('homepage.reviews.review1.text')}</p>
        <h4>{t('homepage.reviews.review1.author')}</h4>
      </div>
      <div className="review-item">
        <p>{t('homepage.reviews.review2.text')}</p>
        <h4>{t('homepage.reviews.review2.author')}</h4>
      </div>
      <div className="review-item">
        <p>{t('homepage.reviews.review3.text')}</p>
        <h4>{t('homepage.reviews.review3.author')}</h4>
      </div>
    </div>
  </section>

  <AuthPopup
    isOpen={isAuthPopupOpen}
    onClose={() => setAuthPopupOpen(false)}
    isLogin={isLogin}
    onForgotPassword={() => setPasswordResetPopupOpen(true)}
  />

  {/* Попап восстановления пароля */}
  {isPasswordResetPopupOpen && (
    <div className="popup-overlay" onClick={() => setPasswordResetPopupOpen(false)}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-btn" onClick={() => setPasswordResetPopupOpen(false)}>
          &times;
        </button>
        <h2>{t('homepage.password_reset.title')}</h2>
        <p>{t('homepage.password_reset.description')}</p>
        <input
          type="email"
          value={resetEmail}
          onChange={(e) => setResetEmail(e.target.value)}
          placeholder={t('homepage.password_reset.placeholder')}
        />
        <button className="btn btn-primary" onClick={handlePasswordReset} disabled={!resetEmail}>
          {t('homepage.password_reset.submit')}
        </button>
      </div>
    </div>
  )}

  <footer className="homepage-footer">
    <p>{t('footer')}</p>
  </footer>
  <SupportButton/>
</div>
  );
};

export default HomePage;
