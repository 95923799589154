import React from 'react';
import './ActivationPopup.css';
import { useTranslation } from 'react-i18next';

const ActivationPopup = ({ isOpen, onClose, activationMessage, errorMessage, activationCode, setActivationCode, onActivate, onRequestCode, isSendingCode, canResend, user }) => {
    const { t } = useTranslation(); // Подключаем мультиязычность
    if (!isOpen) return null; // Не рендерим, если попап закрыт

    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-btn" onClick={onClose}>
                    &times;
                </button>
                <h2>{t('activation.popup.title')}</h2>
                
                <p className="info-message">
                    {t('activation.popup.info', { email: user?.email || '...' })}
                </p>

                {/* Блок сообщений */}
                {activationMessage && <p className="success-message">{activationMessage}</p>}

                <input 
                    type="text" 
                    className='code-input'
                    value={activationCode} 
                    onChange={(e) => setActivationCode(e.target.value)}
                    placeholder={t('activation.popup.placeholder')}
                />

                {errorMessage && <p className="error-message-activation">{errorMessage}</p>}

                <div className="activation-buttons">
                    <button className="btn-secondary-request" onClick={onRequestCode} disabled={isSendingCode || !canResend}>
                        {isSendingCode ? t('activation.popup.sending') : (canResend ? t('activation.popup.resend') : t('activation.popup.resend_wait'))}
                    </button>
                    <button className="btn-primary" onClick={onActivate} disabled={!activationCode.trim()}>
                        {t('activation.popup.confirm')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ActivationPopup;
