import React, { useState, useEffect } from 'react';

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000); // Через 2 сек возвращаем копирование
      })
      .catch(err => console.error("Ошибка копирования:", err));
  };

  // ✅ Следим за `copied` и перерисовываем компонент при его изменении
  useEffect(() => {}, [copied]);

  return (
    <span className="copy-container">
      <span className="copy-text" onClick={handleCopy}>{text}</span> 
      <button className="copy-btn" onClick={handleCopy}>
        <img 
          src={copied ? "/small-check.svg" : "/copy.svg"} 
          alt={copied ? "Скопировано" : "Копировать"} 
          className="copy-icon"
        />
      </button>
    </span>
  );
};

export default CopyButton;
